$(function () {
  console.log('register with amazon');

  window.onAmazonLoginReady = function () {
    amazon.Login.setClientId('amzn1.application-oa2-client.e360d62d47e14817b2bafb150553f45d');
  };
  (function (d) {
    var a = d.createElement('script');
    a.type = 'text/javascript';
    a.async = true;
    a.id = 'amazon-login-sdk';
    a.src = 'https://api-cdn.amazon.com/sdk/login1.js';
    if (d.getElementById('amazon-root') !== null && d.getElementById('amazon-root') !== undefined) {
      d.getElementById('amazon-root').appendChild(a);
    }

  })(document);

  if (document.getElementById('LoginWithAmazonReg') !== null && document.getElementById('LoginWithAmazonReg') !== undefined) {

    document.getElementById('LoginWithAmazonReg').onclick = function () {
      options = {scope: 'profile'};
      console.log(options);
      amazon.Login.setClientId('amzn1.application-oa2-client.e360d62d47e14817b2bafb150553f45d');
      amazon.Login.authorize(options,
        'https://app.ppc.tools/amazonReg');
      console.log('autorize');
      return false;
    };

  }


});

