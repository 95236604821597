$(function () {
  console.log('login with amazon');
  if (document.getElementById('LoginWithAmazon') !== null) {
    document.getElementById('LoginWithAmazon').onclick = function () {
      amazon.Login.setClientId('amzn1.application-oa2-client.e360d62d47e14817b2bafb150553f45d');
      options = {scope: 'profile'};
      amazon.Login.authorize(options,
        'https://app.ppc.tools/amazonLogin');
      return false;
    };
  }
});










